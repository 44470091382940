@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.highlight-present {
  color:green;
  border-radius: 0;
  content: 'P';
}

.highlight-absent {
  color:orange;
  border-radius: 0;
  content: 'A';
}


 

.triangle-mark {
  content: '';
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
  border-radius: 0;
  width: 8px;
  height: 8px;
  position: relative;
  top: -2px;
  right: 1px;
}

.square-mark {
  border-radius: 0;
}

.app {
  text-align: center;
  margin: 20px;
}

.chart-container {
  margin: 20px auto;
  max-width: 800px;
}

.styled-table {
  margin: 20px auto;
  border-collapse: collapse;
  font-size: 1em;
  font-family: 'Arial', sans-serif;
  width: 80%;
  border: 1px solid #dddddd;
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th{
  padding: 12px 15px;
  text-align: center;
  font-weight: bold;
  color: black;
  border: 1px solid black;
}
.styled-table td {
  padding: 12px 15px;
  border: 1px solid black;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.low {
  background-color: lightblue;
}

.styled-table tbody tr.healthy {
  background-color: lightgreen;
}

.styled-table tbody tr.high {
  background-color: lightyellow;
}

.styled-table tbody tr.obesity {
  background-color: lightcoral;
}

.styled-table thead th.low {
  background-color: #6fa8dc;
}

.styled-table thead th.healthy {
  background-color: #93c47d;
}

.styled-table thead th.high {
  background-color: #ffd966;
}

.styled-table thead th.obesity {
  background-color: #e06666;
}

.styled-table td.low {
  background-color: #6fa8dc;
}

.styled-table td.healthy {
  background-color: #93c47d;
}

.styled-table td.high {
  background-color: #ffd966;
}

.styled-table td.obesity {
  background-color: #e06666;
}

.container {
  text-align: center;
}

.styled-table {
  margin-top: 20px;
}

.styled-table td {
  padding: 8px;
}

.styled-table-row.low {
  background-color: #6fa8dc; /* Blue for BMI < 18.5 */
}

.styled-table-row.healthy {
  background-color: #93c47d; /* Green for 18.5 <= BMI <= 24.9 */
}

.styled-table-row.high {
  background-color: #ffd966; /* Yellow for 25 <= BMI <= 29.9 */
}

.styled-table-row.obesity {
  background-color: #e06666; /* Red for BMI >= 30 */
}

.low {
  background-color: #6fa8dc !important; /* Light Blue for BMI < 18.5 */
}

.healthy {
  background-color: #93c47d !important; /* Light Green for 18.5 <= BMI <= 24.9 */
}

.high {
  background-color: #ffd966 !important; /* Light Yellow for 25 <= BMI <= 29.9 */
}

.obesity {
  background-color: #e06666 !important; /* Light Red for BMI >= 30 */
}

.low-bmi {
  background-color: #6fa8dc !important; /* Light Blue for BMI < 18.5 */
}

.healthy-bmi {
  background-color: #93c47d !important; /* Light Green for 18.5 <= BMI <= 24.9 */
}

.high-bmi {
  background-color: #ffd966 !important; /* Light Yellow for 25 <= BMI <= 29.9 */
}

.obesity-bmi {
  background-color: #e06666 !important; /* Light Red for BMI >= 30 */
}



:root {
  --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}
