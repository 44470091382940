.chatbot-container {
    width: 400px;
    height: 600px;
    border: 2px solid #4CAF50;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
}




.chatbot-container:hover {
   
    background-color: #0056b3;
}

.chatbot-header {
    background-color: #4CAF50;
    color: white;
    text-align: center;
    padding: 10px;
    position: relative;
}

.chatbot-header h2 {
    margin: 0;
    font-family: 'Arial', sans-serif;
    font-size: 18px;
}

.close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.chatbot-body {
    flex: 1;
    background-color: #f1f1f1;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatbot-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
}



.chatbot-container iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
}




.open-chatbot-buttonOLD {
    background-color: #4CAF50;
    color: white;
    border: none;
        bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.open-chatbot-buttonOLD:hover {
    background-color: #45a049;
}


.open-chatbot-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
}

.open-chatbot-button:hover {
    background-color: #45a049;
}






.App-header {
    text-align: center;
    margin-top: 20px;
}

main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

