.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.app {
  text-align: center;
  margin: 20px;
}

.chart-container {
  margin: 20px auto;
  max-width: 800px;
}

.styled-table {
  margin: 20px auto;
  border-collapse: collapse;
  font-size: 1em;
  font-family: 'Arial', sans-serif;
  width: 80%;
  border: 1px solid #dddddd;
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th{
  padding: 12px 15px;
  text-align: center;
  font-weight: bold;
  color: black;
  border: 1px solid black;
}
.styled-table td {
  padding: 12px 15px;
  border: 1px solid black;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.low {
  background-color: lightblue;
}

.styled-table tbody tr.healthy {
  background-color: lightgreen;
}

.styled-table tbody tr.high {
  background-color: lightyellow;
}

.styled-table tbody tr.obesity {
  background-color: lightcoral;
}

.styled-table thead th.low {
  background-color: #6fa8dc;
}

.styled-table thead th.healthy {
  background-color: #93c47d;
}

.styled-table thead th.high {
  background-color: #ffd966;
}

.styled-table thead th.obesity {
  background-color: #e06666;
}

.styled-table td.low {
  background-color: #6fa8dc;
}

.styled-table td.healthy {
  background-color: #93c47d;
}

.styled-table td.high {
  background-color: #ffd966;
}

.styled-table td.obesity {
  background-color: #e06666;
}

.container {
  text-align: center;
}

.styled-table {
  margin-top: 20px;
}

.styled-table td {
  padding: 8px;
}

.styled-table-row.low {
  background-color: #6fa8dc; /* Blue for BMI < 18.5 */
}

.styled-table-row.healthy {
  background-color: #93c47d; /* Green for 18.5 <= BMI <= 24.9 */
}

.styled-table-row.high {
  background-color: #ffd966; /* Yellow for 25 <= BMI <= 29.9 */
}

.styled-table-row.obesity {
  background-color: #e06666; /* Red for BMI >= 30 */
}

.low {
  background-color: #6fa8dc !important; /* Light Blue for BMI < 18.5 */
}

.healthy {
  background-color: #93c47d !important; /* Light Green for 18.5 <= BMI <= 24.9 */
}

.high {
  background-color: #ffd966 !important; /* Light Yellow for 25 <= BMI <= 29.9 */
}

.obesity {
  background-color: #e06666 !important; /* Light Red for BMI >= 30 */
}

.legend {
  background-color: #e4e0d4 !important; /* Light Red for BMI >= 30 */
}

.low-bmi {
  background-color: #6fa8dc !important; /* Light Blue for BMI < 18.5 */
}

.healthy-bmi {
  background-color: #93c47d !important; /* Light Green for 18.5 <= BMI <= 24.9 */
}

.high-bmi {
  background-color: #ffd966 !important; /* Light Yellow for 25 <= BMI <= 29.9 */
}

.obesity-bmi {
  background-color: #e06666 !important; /* Light Red for BMI >= 30 */
}
