
@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');


/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body {
  width: 100%;
  height: 100vh;
  color: #fff;
  background: linear-gradient(-45deg, #f78664, #f74d8e, #3bc5f7, #3cface);

  -webkit-animation: gradient 5s ease infinite;
  animation: gradient 5s ease infinite;
  background-size: 400% 400%;
  position: relative;
}

*/
  /* background-color: #7f5a83; */
  /* background-image: linear-gradient(-45deg, #c31432, #240b36); */

  /*  background: linear-gradient(-45deg, #f78664, #f74d8e, #3bc5f7, #3cface); */

  /* background: linear-gradient(-45deg, #f78664,  #f74d8e,  #3cface); */


body {
  width: 100%;
  height: 100%;
  color: #fff;
  -webkit-animation: gradient 15s ease infinite;
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  position: relative;
}


@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



.full-page:after {
  background-color: transparent;
}

/* background-color: #0cbaba;
background-image: linear-gradient(315deg, #0cbaba 0%, #380036 74%); */



.footer-nav {
  font-size: 30px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --amplify-primary-color: #006eff;
  --amplify-primary-tint: #005ed9;
  --amplify-primary-shade: #005ed9;
}

