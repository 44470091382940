.ishs__header {
    display: flex;
    flex-direction: row;
}

.ishs__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.ishs__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.ishs__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.ishs__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.ishs__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.ishs__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ishs__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.ishs__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.ishs__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.ishs__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ishs__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .ishs__header {
        flex-direction: column;
    }

    .ishs__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .ishs__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .ishs__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .ishs__header-content__people {
        flex-direction: column;
    }

    .ishs__header-content__people p {
        margin: 0;
    }

    .ishs__header-content__input input,
    .ishs__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .ishs__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .ishs__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .ishs__header-content__input input,
    .ishs__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}

.wp-block-social-links {
    background: none;
    box-sizing: border-box;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    text-indent: 0
}

.wp-block-social-links .wp-social-link a,.wp-block-social-links .wp-social-link a:hover {
    border-bottom: 0;
    box-shadow: none;
    text-decoration: none
}

.wp-block-social-links .wp-social-link a {
    padding: .25em
}

.wp-block-social-links .wp-social-link svg {
    height: 1em;
    width: 1em
}

.wp-block-social-links .wp-social-link span:not(.screen-reader-text) {
    font-size: .65em;
    margin-left: .5em;
    margin-right: .5em
}

.wp-block-social-links.has-small-icon-size {
    font-size: 16px
}

.wp-block-social-links,.wp-block-social-links.has-normal-icon-size {
    font-size: 24px
}

.wp-block-social-links.has-large-icon-size {
    font-size: 36px
}

.wp-block-social-links.has-huge-icon-size {
    font-size: 48px
}

.wp-block-social-links.aligncenter {
    display: flex;
    justify-content: center
}

.wp-block-social-links.alignright {
    justify-content: flex-end
}

.wp-block-social-link {
    border-radius: 9999px;
    display: block;
    height: auto;
    transition: transform .1s ease
}

@media (prefers-reduced-motion:reduce) {
    .wp-block-social-link {
        transition-delay: 0s;
        transition-duration: 0s
    }
}

.wp-block-social-link a {
    align-items: center;
    display: flex;
    line-height: 0;
    transition: transform .1s ease
}

.wp-block-social-link:hover {
    transform: scale(1.1)
}

.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor,.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor svg,.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor:active,.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor:hover,.wp-block-social-links .wp-block-social-link .wp-block-social-link-anchor:visited {
    fill: currentColor;
    color: currentColor
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link {
    background-color: #f0f0f0;
    color: #444
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-amazon {
    background-color: #f90;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-bandcamp {
    background-color: #1ea0c3;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-behance {
    background-color: #0757fe;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-codepen {
    background-color: #1e1f26;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-deviantart {
    background-color: #02e49b;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dribbble {
    background-color: #e94c89;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dropbox {
    background-color: #4280ff;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-etsy {
    background-color: #f45800;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-facebook {
    background-color: #1778f2;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-fivehundredpx {
    background-color: #000;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-flickr {
    background-color: #0461dd;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-foursquare {
    background-color: #e65678;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-github {
    background-color: #24292d;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-goodreads {
    background-color: #eceadd;
    color: #382110
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-google {
    background-color: #ea4434;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-instagram {
    background-color: #f00075;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-lastfm {
    background-color: #e21b24;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-linkedin {
    background-color: #0d66c2;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-mastodon {
    background-color: #3288d4;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-medium {
    background-color: #02ab6c;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-meetup {
    background-color: #f6405f;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-patreon {
    background-color: #ff424d;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pinterest {
    background-color: #e60122;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pocket {
    background-color: #ef4155;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-reddit {
    background-color: #ff4500;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-skype {
    background-color: #0478d7;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-snapchat {
    stroke: #000;
    background-color: #fefc00;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-soundcloud {
    background-color: #ff5600;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-spotify {
    background-color: #1bd760;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-telegram {
    background-color: #2aabee;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tiktok {
    background-color: #000;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tumblr {
    background-color: #011835;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitch {
    background-color: #6440a4;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitter {
    background-color: #1da1f2;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vimeo {
    background-color: #1eb7ea;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vk {
    background-color: #4680c2;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-wordpress {
    background-color: #3499cd;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-whatsapp {
    background-color: #25d366;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-yelp {
    background-color: #d32422;
    color: #fff
}

.wp-block-social-links:not(.is-style-logos-only) .wp-social-link-youtube {
    background-color: red;
    color: #fff
}

.wp-block-social-links.is-style-logos-only .wp-social-link {
    background: none
}

.wp-block-social-links.is-style-logos-only .wp-social-link a {
    padding: 0
}

.wp-block-social-links.is-style-logos-only .wp-social-link svg {
    height: 1.25em;
    width: 1.25em
}

.wp-block-social-links.is-style-logos-only .wp-social-link-amazon {
    color: #f90
}

.wp-block-social-links.is-style-logos-only .wp-social-link-bandcamp {
    color: #1ea0c3
}

.wp-block-social-links.is-style-logos-only .wp-social-link-behance {
    color: #0757fe
}

.wp-block-social-links.is-style-logos-only .wp-social-link-codepen {
    color: #1e1f26
}

.wp-block-social-links.is-style-logos-only .wp-social-link-deviantart {
    color: #02e49b
}

.wp-block-social-links.is-style-logos-only .wp-social-link-dribbble {
    color: #e94c89
}

.wp-block-social-links.is-style-logos-only .wp-social-link-dropbox {
    color: #4280ff
}

.wp-block-social-links.is-style-logos-only .wp-social-link-etsy {
    color: #f45800
}

.wp-block-social-links.is-style-logos-only .wp-social-link-facebook {
    color: #1778f2
}

.wp-block-social-links.is-style-logos-only .wp-social-link-fivehundredpx {
    color: #000
}

.wp-block-social-links.is-style-logos-only .wp-social-link-flickr {
    color: #0461dd
}

.wp-block-social-links.is-style-logos-only .wp-social-link-foursquare {
    color: #e65678
}

.wp-block-social-links.is-style-logos-only .wp-social-link-github {
    color: #24292d
}

.wp-block-social-links.is-style-logos-only .wp-social-link-goodreads {
    color: #382110
}

.wp-block-social-links.is-style-logos-only .wp-social-link-google {
    color: #ea4434
}

.wp-block-social-links.is-style-logos-only .wp-social-link-instagram {
    color: #f00075
}

.wp-block-social-links.is-style-logos-only .wp-social-link-lastfm {
    color: #e21b24
}

.wp-block-social-links.is-style-logos-only .wp-social-link-linkedin {
    color: #0d66c2
}

.wp-block-social-links.is-style-logos-only .wp-social-link-mastodon {
    color: #3288d4
}

.wp-block-social-links.is-style-logos-only .wp-social-link-medium {
    color: #02ab6c
}

.wp-block-social-links.is-style-logos-only .wp-social-link-meetup {
    color: #f6405f
}

.wp-block-social-links.is-style-logos-only .wp-social-link-patreon {
    color: #ff424d
}

.wp-block-social-links.is-style-logos-only .wp-social-link-pinterest {
    color: #e60122
}

.wp-block-social-links.is-style-logos-only .wp-social-link-pocket {
    color: #ef4155
}

.wp-block-social-links.is-style-logos-only .wp-social-link-reddit {
    color: #ff4500
}

.wp-block-social-links.is-style-logos-only .wp-social-link-skype {
    color: #0478d7
}

.wp-block-social-links.is-style-logos-only .wp-social-link-snapchat {
    stroke: #000;
    color: #fff
}

.wp-block-social-links.is-style-logos-only .wp-social-link-soundcloud {
    color: #ff5600
}

.wp-block-social-links.is-style-logos-only .wp-social-link-spotify {
    color: #1bd760
}

.wp-block-social-links.is-style-logos-only .wp-social-link-telegram {
    color: #2aabee
}

.wp-block-social-links.is-style-logos-only .wp-social-link-tiktok {
    color: #000
}

.wp-block-social-links.is-style-logos-only .wp-social-link-tumblr {
    color: #011835
}

.wp-block-social-links.is-style-logos-only .wp-social-link-twitch {
    color: #6440a4
}

.wp-block-social-links.is-style-logos-only .wp-social-link-twitter {
    color: #1da1f2
}

.wp-block-social-links.is-style-logos-only .wp-social-link-vimeo {
    color: #1eb7ea
}

.wp-block-social-links.is-style-logos-only .wp-social-link-vk {
    color: #4680c2
}

.wp-block-social-links.is-style-logos-only .wp-social-link-whatsapp {
    color: #25d366
}

.wp-block-social-links.is-style-logos-only .wp-social-link-wordpress {
    color: #3499cd
}

.wp-block-social-links.is-style-logos-only .wp-social-link-yelp {
    color: #d32422
}

.wp-block-social-links.is-style-logos-only .wp-social-link-youtube {
    color: red
}

.wp-block-social-links.is-style-pill-shape .wp-social-link {
    width: auto
}

.wp-block-social-links.is-style-pill-shape .wp-social-link a {
    padding-left: .66667em;
    padding-right: .66667em
}

.mystyle {
    color: #ffffff;
    background-color: #63a18f;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  